import React from "react";
import { HeaderContainer, HeaderLogo, HeaderIcon } from "./style";

const Header = ({ onSearch, onLogout, onLogoClick }) => {
  return (
    <HeaderContainer>
      <HeaderIcon onClick={() => onLogout()} src="/icons/logout.svg" />
      <HeaderLogo
        onClick={() => {
          if (onLogoClick) {
            onLogoClick();
          }
        }}
        src="/images/tkt.svg"
      />
      <HeaderIcon
        style={{
          opacity: onSearch ? 1 : 0,
          pointerEvents: onSearch ? "all" : "none",
        }}
        onClick={() => onSearch()}
        src="/icons/search.svg"
      />
    </HeaderContainer>
  );
};

export default Header;
