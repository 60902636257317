import React, { useState, useEffect } from "react";
import TabSwitcher from "../components/common/tabSwitcher";
import Header from "../components/common/header";
import EmptyEntry from "../components/common/emptyEntry";
import TabContainer from "../components/common/tabContainer/tabContainer";

function MainPage({
  userList,
  onUserSelect,
  onTabSwitch,
  onSearch,
  onLogout,
  tabType,
  onLogoClick,
  selectedEvent,
}) {
  const [filteredUsers, setFilteredUsers] = useState(userList || []);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (userList && userList.length > 0) {
      setFilteredUsers(
        userList.filter((x) => {
          if (activeTab === 0) {
            onTabSwitch("pending");
            return x.Status === 1;
          } else if (activeTab === 1) {
            onTabSwitch("approved");
            return x.Status === 2;
          } else if (activeTab === 3) {
            onTabSwitch("delayed");
            return x.Status === 4;
          } else {
            onTabSwitch("blocked");
            return x.Status === 3;
          }
        })
      );
    }
  }, [userList, activeTab]);
  return (
    <>
      {/* {userList && userList.length > 0 && ( */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          onSearch={onSearch}
          onLogout={onLogout}
          onLogoClick={() => onLogoClick()}
        />
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            padding: "0.75rem 1rem",
            borderTop: "1px solid #f4f4f4",
            borderBottom: "1px solid #f4f4f4",
          }}
        >
          <p style={{ margin: "0", fontSize: "0.75rem", fontWeight: "bold" }}>
            {selectedEvent.ItemName}
          </p>
          <img
            onClick={() => onLogoClick()}
            style={{ cursor: "pointer" }}
            src="/icons/close.svg"
            alt=""
          />
        </div>
        <TabSwitcher
          pendingCount={userList.filter((x) => x.Status === 1).length}
          blockedCount={userList.filter((x) => x.Status === 3).length}
          approvedCount={userList.filter((x) => x.Status === 2).length}
          delayedCount={userList.filter((x) => x.Status === 4).length}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
          type={tabType}
        />
        <TabContainer
          users={filteredUsers}
          onItemClick={(item) => {
            onUserSelect(item.UserId);
          }}
        />
      </div>
      {/* )} */}
      {/* 
      {userList.length < 1 && (
        <>
          <div style={{ width: "100%" }}>
            <Header onSearch={onSearch} onLogout={onLogout} />
            <p
              style={{
                color: "#000000",
                fontSize: "15px",
                lineHeight: "21px",
                marginTop: "60px",
                textAlign: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontFamily: "BPG WEB 001 Caps",
              }}
            >
              თქვენს მომხმარებელზე არ არის Fb face control-იანი ღონისძიება
            </p>
            <EmptyEntry />
          </div>
        </>
      )} */}

      {(!filteredUsers || filteredUsers.length < 1) && <EmptyEntry />}
    </>
  );
}

export default MainPage;
