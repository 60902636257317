import styled from "styled-components";

export const ListItemWrapper = styled("div")`
  display: grid;
  grid-template-columns: 35px 1fr 20px;
  align-items: center;
  padding: 15px 20px;
  &:nth-of-type(2n + 1) {
    background-color: #f9fafa;
  }
`;

export const ListItemName = styled("span")`
  color: #3a4049;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;

export const ListItemStatusBadge = styled("img")`
  border-radius: 50%;
`;

export const ListItemArrow = styled("img")`
  height: 11px;
  width: 6px;
  justify-self: flex-end;
`;
