import React, { useState, useEffect } from "react";
import {
  SearchContainer,
  SearchInputWrapper,
  SearchIcon,
  SearchInput,
  CloseIcon,
  SearchListItem,
} from "./style";

const SearchInputSection = ({ onClose, userList, onUserSelect }) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    const clonedUserList = JSON.parse(JSON.stringify(userList));
    if (clonedUserList && clonedUserList.length > 0) {
      setFilteredUsers(
        clonedUserList.filter(
          (x) =>
            x.FullName.toLowerCase().includes(searchValue.toLowerCase()) &&
            !!searchValue
        )
      );
    }
  }, [userList, searchValue]);
  return (
    <SearchContainer>
      <SearchInputWrapper>
        <SearchIcon src="/icons/search.svg" />
        <SearchInput
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
          }}
        />
        <CloseIcon
          onClick={() => {
            onClose();
          }}
          src="/icons/close.svg"
        />
      </SearchInputWrapper>
      {filteredUsers.map((user) => {
        return (
          <SearchListItem
            onClick={() => {
              onUserSelect(
                user.UserId,
                user.Status === 2
                  ? "approved"
                  : user.Status === 3
                  ? "blocked"
                  : user.Status === 1
                  ? "pending"
                  : "delayed"
              );
              onClose();
            }}
            key={`search-${user.UserId}`}
            name={user.FullName}
            status={
              user.Status === 2
                ? "approved"
                : user.Status === 3
                ? "blocked"
                : user.Status === 1
                ? "pending"
                : "delayed"
            }
          />
        );
      })}
    </SearchContainer>
  );
};

export default SearchInputSection;
