import React from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

import ListItem from "../listItem";

const TabContainer = ({ users, onItemClick }) => {
  const parentRef = React.useRef();
  const rowVirtualizer = useVirtualizer({
    count: users.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
  });

  return (
    <div
      ref={parentRef}
      style={{
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {/* Only the visible items in the virtualizer, manually positioned to be in view */}
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = users[virtualItem.index];
          return (
            <div
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <ListItem
                onClick={() => onItemClick(item)}
                key={item.UserId}
                name={item.FullName}
                status={
                  item.Status === 2
                    ? "approved"
                    : item.Status === 3
                    ? "blocked"
                    : item.Status === 1
                    ? "pending"
                    : "delayed"
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabContainer;
