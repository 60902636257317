import { motion } from "framer-motion";
import styled from "styled-components";

export const ButtonWrapper = styled(motion.button)`
  border-radius: 9px;
  background-color: #10baeb;
  box-shadow: 0 13px 15px -6px rgba(32, 165, 231, 0.49);
  padding: 20px 33px;
  color: #ffffff;
  font-family: "BPG Web 001 Caps";
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  margin-top: auto;
`;
