import React from "react";
import { ActionButtonContainer, ActionButton, ActionButtonIcon } from "./style";

const TinderActions = ({ type, onBlock, onApprove, onSkip }) => {
  return (
    <ActionButtonContainer>
      {["pending", "approved", "delayed"].includes(type) && (
        <ActionButton onClick={() => onBlock()}>
          <ActionButtonIcon src="/icons/reject.svg" />
        </ActionButton>
      )}
      {type !== "delayed" && (
        <ActionButton onClick={() => onSkip()}>
          <ActionButtonIcon src="/icons/skip.svg" />
        </ActionButton>
      )}
      {["pending", "blocked", "delayed"].includes(type) && (
        <ActionButton onClick={() => onApprove()}>
          <ActionButtonIcon src="/icons/approve.svg" />
        </ActionButton>
      )}
    </ActionButtonContainer>
  );
};

export default TinderActions;
