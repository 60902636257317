import React from "react";
import Header from "../components/common/header";
const EventsListPage = ({ userEvents, onLogout, onSelectEvent }) => {
  return (
    <>
      {/* {userList && userList.length > 0 && ( */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header onLogout={onLogout} />
        <div
          style={{
            maxHeight: "100%",
            overflowY: "auto",
          }}
        >
          {userEvents.map((x) => {
            return (
              <div
                onClick={() => onSelectEvent(x)}
                style={{
                  width: "calc(100% - 2rem)",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                  background: "#fcfcfc",
                  borderRadius: 9,
                  padding: "0.75rem",
                  cursor: "pointer",
                }}
                key={`event-list-${x.ItemId}`}
              >
                <img
                  style={{ width: "100%", borderRadius: 9 }}
                  src={`https://static.tkt.ge/img/${x.PosterUlr}`}
                  alt=""
                />
                <p
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "bold",
                    margin: "0",
                    marginTop: "0.5rem",
                  }}
                >
                  {x.ItemName}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EventsListPage;
