import styled from "styled-components";

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px;
`;

export const HeaderTitle = styled("span")`
  color: #1b1f34;
  font-family: "BPG WEB 001 Caps";
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitleBadge = styled("span")`
  height: 12px;
  width: 12px;
  background-color: ${(props) =>
    props.type === "approved"
      ? "#43e09d"
      : props.type === "blocked"
      ? "#fe3c6a"
      : props.type === "pending"
      ? "#07b7ee"
      : "#ffc814"};
  border-radius: 50%;
  margin-right: 6px;
`;

export const HeaderCount = styled("span")`
  color: #1b1f34;
  font-family: "BPG WEB 001 Caps";
  font-size: 18px;
  line-height: 21px;
  margin-top: 7px;
`;

export const HeaderClose = styled("img")`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 17px;
  right: 25px;
`;
