import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.tkt.ge/v2/",
});

const ApiLogin = async (username, password) => {
  try {
    const resp = await (
      await axiosInstance.post(
        `merchantusers/token?api_key=dad88b07-cd98-4a26-9171-59de30f3318b`,
        {
          username,
          password,
        }
      )
    ).data;
    if (resp.Success) {
      return resp.AccessToken;
    } else {
      return { error: true };
    }
  } catch (e) {
    return { error: true };
  }
};
const ApiGetUserEvent = async (accessToken) => {
  try {
    const resp = await (
      await axiosInstance.get(
        `/merchantusers/purchase-requests/event?api_key=dad88b07-cd98-4a26-9171-59de30f3318b`,
        { headers: { authorization: "Bearer " + accessToken } }
      )
    ).data;
    if (resp.Data) {
      return resp.Data.Items;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

const ApiGetUsers = async ({ eventId, limit, offset }, accessToken) => {
  try {
    const searchParams = new URLSearchParams({
      eventId,
      limit: limit || 100000,
      offset: offset || 0,
      api_key: "7d8d34d1-e9af-4897-9f0f-5c36c179be77",
    });
    const resp = await (
      await axiosInstance.get(
        `/merchantusers/purchase-requests?${searchParams.toString()}`,
        { headers: { authorization: "Bearer " + accessToken } }
      )
    ).data;

    if (resp.Data) {
      return resp.Data.List;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

const ApiChangeStatus = async (requestId, status, accessToken) => {
  try {
    const resp = await (
      await axiosInstance.post(
        `/merchantusers/purchase-requests?api_key=dad88b07-cd98-4a26-9171-59de30f3318b`,
        {
          PurchaseRequestId: requestId,
          EventId: 119993,
          Status: status,
        },
        { headers: { authorization: "Bearer " + accessToken } }
      )
    ).data;
    if (resp.Data.Ok) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const ApiClient = {
  login: ApiLogin,
  getUsers: ApiGetUsers,
  changeStatus: ApiChangeStatus,
  getUserEvent: ApiGetUserEvent,
};

export default ApiClient;
