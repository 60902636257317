import React, { useState, useEffect } from "react";
import "./App.css";
import MainPage from "./pages/main";
import TinderPage from "./pages/tinder";
import LoginPage from "./pages/login";
import SearchInputSection from "./components/search";
import ApiClient from "./api";
import Cookies from "js-cookie";
import { Layout, LayoutItem } from "./components/layout";
import useAuth from "./hooks/useAuth";
import EventsListPage from "./pages/eventList";
import { useQuery } from "react-query";

function App() {
  const [searchActive, setSearchActive] = useState(false);
  const [isAuth, accessToken, setAccessToken] = useAuth();
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState();
  const [didTutorial, setDidTutorial] = useState(
    Cookies.get("tkt-tinder-did-tutorial")
  );
  const events = useQuery(
    ["events"],
    () => ApiClient.getUserEvent(accessToken),
    {
      enabled: Boolean(isAuth),
      initialData: [],
    }
  );
  useQuery(
    ["users", selectedEvent?.ItemId],
    () => ApiClient.getUsers({ eventId: selectedEvent?.ItemId }, accessToken),
    {
      enabled: Boolean(isAuth && selectedEvent?.ItemId),
      initialData: [],
      onSuccess: (data) => {
        setUserList(data);
      },
    }
  );
  const [listType, setListType] = useState("pending");
  useEffect(() => {
    if (!userList || userList.length < 1) return;
    setFilteredUserList(
      userList.filter(
        (x) =>
          x.Status ===
          (listType === "pending"
            ? 1
            : listType === "blocked"
            ? 3
            : listType === "approved"
            ? 2
            : 4)
      )
    );
  }, [userList, listType]);

  const onLogin = (token) => {
    Cookies.set("tkt-tinder-token", token, { expires: 0.5 });
    setAccessToken(token);
  };
  const onTutorialEnd = () => {
    Cookies.set("tkt-tinder-did-tutorial", true, { expires: 360 });
    setDidTutorial(true);
  };
  const approveUser = async (userId) => {
    const clonedUserList = JSON.parse(JSON.stringify(userList));
    const user = clonedUserList.find((x) => x.UserId === userId);
    const oldStatus = user.Status;
    if (user) {
      user.Status = 2;
    }
    setUserList(clonedUserList);
    if (user) {
      const resp = await ApiClient.changeStatus(
        user.TicketPurchaseRequestId,
        2,
        accessToken
      );
      if (!resp) {
        const userIndex = clonedUserList.findIndex((x) => x.UserId === userId);
        user.Status = oldStatus;
        clonedUserList.splice(userIndex, 1);
        clonedUserList.push(user);
        setUserList(JSON.parse(JSON.stringify(clonedUserList)));
      }
    }
  };
  const blockUser = async (userId) => {
    const clonedUserList = JSON.parse(JSON.stringify(userList));
    const user = clonedUserList.find((x) => x.UserId === userId);
    const oldStatus = user.Status;
    if (user) {
      user.Status = 3;
    }
    setUserList(clonedUserList);
    if (user) {
      const resp = await ApiClient.changeStatus(
        user.TicketPurchaseRequestId,
        3,
        accessToken
      );
      if (!resp) {
        const userIndex = clonedUserList.findIndex((x) => x.UserId === userId);
        user.Status = oldStatus;
        clonedUserList.splice(userIndex, 1);
        clonedUserList.push(user);
        setUserList(JSON.parse(JSON.stringify(clonedUserList)));
      }
    }
  };
  const skipUser = async (userId) => {
    const clonedUserList = JSON.parse(JSON.stringify(userList));
    const user = clonedUserList.find((x) => x.UserId === userId);
    const oldStatus = user.Status;
    if (user) {
      user.Status = 4;
    }
    setUserList(clonedUserList);
    if (user) {
      const resp = await ApiClient.changeStatus(
        user.TicketPurchaseRequestId,
        4,
        accessToken
      );
      if (!resp) {
        const userIndex = clonedUserList.findIndex((x) => x.UserId === userId);
        user.Status = oldStatus;
        clonedUserList.splice(userIndex, 1);
        clonedUserList.push(user);
        setUserList(JSON.parse(JSON.stringify(clonedUserList)));
      }
    }
  };
  return (
    <Layout>
      <LayoutItem>
        {!accessToken && (
          <>
            <LoginPage onLogin={onLogin} />
          </>
        )}
        {accessToken && !selectedEvent && (
          <EventsListPage
            onLogout={() => {
              setAccessToken(null);
              Cookies.remove("tkt-tinder-token");
              window.location.reload();
            }}
            userEvents={events.data}
            onSelectEvent={(_eventId) => setSelectedEvent(_eventId)}
          />
        )}
        {accessToken && selectedEvent && userList && (
          <>
            <MainPage
              selectedEvent={selectedEvent}
              onLogoClick={() => {
                setSelectedEvent(undefined);
                setActiveUser(null);
                setFilteredUserList([]);
                setUserList([]);
              }}
              onSearch={() => {
                setSearchActive(true);
              }}
              onLogout={() => {
                setAccessToken(null);
                Cookies.remove("tkt-tinder-token");
                window.location.reload();
              }}
              tabType={listType}
              onTabSwitch={(type) => setListType(type)}
              onUserSelect={(userId) => {
                setActiveUser(userId);
              }}
              userList={userList}
            />
          </>
        )}
        {accessToken && activeUser && selectedEvent && userList && (
          <TinderPage
            didTutorial={didTutorial}
            onTutorialEnd={onTutorialEnd}
            onApprove={(userId) => {
              approveUser(userId);
            }}
            onBlock={(userId) => {
              blockUser(userId);
            }}
            onSkip={(userId) => {
              skipUser(userId);
            }}
            onSetActiveUser={(activeUser) => {
              setActiveUser(activeUser);
            }}
            startUser={activeUser}
            type={listType}
            userList={filteredUserList}
            onClose={() => {
              setActiveUser(null);
            }}
            user={userList.find((x) => x.userId === activeUser)}
          />
        )}
        {searchActive && (
          <SearchInputSection
            userList={userList}
            onUserSelect={(userId, type) => {
              setListType(type);
              setActiveUser(userId);
            }}
            onClose={() => {
              setSearchActive(false);
            }}
          />
        )}
      </LayoutItem>
    </Layout>
  );
}

export default App;
