import React, { useEffect } from "react";
import TinderHeader from "../components/tinder/header";
import TinderCard from "../components/tinder/card";

const TinderPage = ({
  onClose,
  userList,
  type,
  startUser,
  onBlock,
  onApprove,
  onSkip,
  onSetActiveUser,
  onTutorialEnd,
  didTutorial,
}) => {
  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      document.querySelector("body").style.overflow = null;
    };
  }, []);
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: "#F8F9FA",
        paddingBottom: 20,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 3,
      }}
    >
      <TinderHeader type={type} onClose={onClose} count={userList.length} />
      <TinderCard
        didTutorial={didTutorial}
        onTutorialEnd={() => {
          onTutorialEnd();
        }}
        blockUser={onBlock}
        approveUser={onApprove}
        skipUser={onSkip}
        onSetActiveUser={onSetActiveUser}
        startUser={startUser}
        userList={userList}
        // swipeable={type === "pending"}
        swipeable={true}
        type={type}
      />
    </div>
  );
};

export default TinderPage;
