import styled from "styled-components";

export const Layout = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LayoutItem = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 500px;
  max-height: 800px;
  height: 100%;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0 5px 30px -7px rgba(3, 23, 44, 0.4);
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
