import styled from "styled-components";

export const ActionButtonContainer = styled("div")`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 36px;
  margin-top: 60px;
`;

export const ActionButton = styled("span")`
  width: 63px;
  height: 63px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(151, 155, 171, 0.02);
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(14, 7, 92, 0.05);
  cursor: pointer;
`;

export const ActionButtonIcon = styled("img")`
  object-fit: contain;
  cursor: pointer;
`;
