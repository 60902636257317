import React from "react";
import { ButtonWrapper } from "./style";

const Button = (props) => {
  return (
    <ButtonWrapper {...props}>
      {props.children}
      <img src="/icons/arrownext.svg" alt="" />
    </ButtonWrapper>
  );
};
export default Button;
