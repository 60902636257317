import React, { useState } from "react";
import { LoginDiv, LoginLogo, LogInImg } from "./style";
import Input from "../input";
import Button from "../button";
import ApiClient from "../../../api";

const LoginContainer = ({ onLogin }) => {
  const [fullName, setFullName] = useState("");
  const [psw, setPsw] = useState("");
  const [didError, setDidError] = useState(false);
  const checkForNext = async () => {
    if (fullName.length > 0 && psw.length > 0) {
      const token = await ApiClient.login(fullName, psw);
      if (token && token.error) {
        setDidError(true);
      } else {
        onLogin(token);
      }
    }
  };

  return (
    <LoginDiv
      onKeyUp={(e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13) {
          checkForNext();
        }
      }}
    >
      <LoginLogo src="/icons/tktlogo.svg" />
      <LogInImg src="/icons/tktlogin.svg" />
      <Input
        didError={didError}
        title="მომხმარებელი"
        onChange={(val) => {
          setFullName(val);
          setDidError(false);
        }}
        value={fullName}
      />
      <Input
        didError={didError}
        title="პაროლი"
        onChange={(val) => {
          setPsw(val);
          setDidError(false);
        }}
        value={psw}
        type="password"
      />
      <Button
        style={{ marginTop: "auto" }}
        hasArrow
        onClick={() => checkForNext()}
      >
        ავტორიზაცია
      </Button>
    </LoginDiv>
  );
};

export default LoginContainer;
