import React, { useState, useEffect, useRef } from "react";
import {
  TabContainer,
  TabItemWrapper,
  TabSwitcherText,
  TabSwitcherCount,
  TabSwitcherUnderline,
} from "./style";
import { useMotionValue, useSpring } from "framer-motion";

const TabSwitcher = (props) => {
  const curTabRef = useRef(null);
  const containerRef = useRef(null);

  const [activeTab, setActiveTab] = useState(
    props.type === "pending"
      ? 0
      : props.type === "approved"
      ? 1
      : props.type === "delayed"
      ? 3
      : 2
  );
  const tabWidth = useMotionValue(0);
  const tabLeft = useMotionValue(0);
  useEffect(() => {
    props.setActiveTab(activeTab);
  }, [activeTab]);
  useEffect(() => {
    setActiveTab(
      props.type === "pending"
        ? 0
        : props.type === "approved"
        ? 1
        : props.type === "delayed"
        ? 3
        : 2
    );
  }, [props.type]);
  const tabColor = useSpring("#FFC814", {
    mass: 1,
    stiffness: 300,
    damping: 30,
  });
  const animWidth = useSpring(tabWidth, {
    mass: 1,
    stiffness: 300,
    damping: 30,
  });
  const animLeft = useSpring(tabLeft, {
    mass: 1,
    stiffness: 300,
    damping: 30,
  });
  useEffect(() => {
    if (curTabRef.current) {
      const rect = curTabRef.current.getBoundingClientRect();
      const rectCont = containerRef.current.getBoundingClientRect();
      const wDelta = (window.innerWidth - rectCont.width) / 2;
      tabWidth.set(rect.width);
      tabLeft.set(rect.left - wDelta);
    }
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        tabColor.set("#43E09D");
        break;
      case 2:
        tabColor.set("#FE3C6A");
        break;
      case 3:
        tabColor.set("#FFC814");
        break;
      default:
        tabColor.set("#07b7ee");
        break;
    }
    if (curTabRef.current) {
      const rect = curTabRef.current.getBoundingClientRect();
      const rectCont = containerRef.current.getBoundingClientRect();
      const wDelta = (window.innerWidth - rectCont.width) / 2;
      tabWidth.set(rect.width);
      tabLeft.set(rect.left - wDelta);
    }
  }, [activeTab, curTabRef]);
  return (
    <TabContainer ref={containerRef}>
      <TabItemWrapper
        onClick={() => {
          setActiveTab(0);
        }}
        ref={activeTab === 0 ? curTabRef : null}
      >
        <TabSwitcherCount>{props.pendingCount}</TabSwitcherCount>
        <TabSwitcherText>მოლოდინში</TabSwitcherText>
      </TabItemWrapper>
      <TabItemWrapper
        onClick={() => {
          setActiveTab(1);
        }}
        ref={activeTab === 1 ? curTabRef : null}
      >
        <TabSwitcherCount>{props.approvedCount}</TabSwitcherCount>
        <TabSwitcherText>დადასტურებული</TabSwitcherText>
      </TabItemWrapper>
      <TabItemWrapper
        onClick={() => {
          setActiveTab(2);
        }}
        ref={activeTab === 2 ? curTabRef : null}
      >
        <TabSwitcherCount>{props.blockedCount}</TabSwitcherCount>
        <TabSwitcherText>უარყოფილი</TabSwitcherText>
      </TabItemWrapper>
      <TabItemWrapper
        onClick={() => {
          setActiveTab(3);
        }}
        ref={activeTab === 3 ? curTabRef : null}
      >
        <TabSwitcherCount>{props.delayedCount}</TabSwitcherCount>
        <TabSwitcherText>გადადებული</TabSwitcherText>
      </TabItemWrapper>
      <TabSwitcherUnderline
        style={{
          width: animWidth,
          left: animLeft,
          backgroundColor: tabColor,
        }}
      />
    </TabContainer>
  );
};

export default TabSwitcher;
