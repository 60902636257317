import styled from "styled-components";
import { motion } from "framer-motion";
export const TabContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  position: sticky;
  top: 56px;
  background-color: #fff;
  z-index: 2;
  border-bottom: 1px solid #f4f4f4;
`;

export const TabItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabSwitcherCount = styled("span")`
  color: #191b25;
  font-family: "BPG WEB 001 Caps";
  font-size: 14px;
  line-height: 16px;
`;

export const TabSwitcherText = styled("span")`
  color: #25262b;
  font-family: "BPG WEB 001 Caps";
  font-size: 9px;
  line-height: 14px;
  margin-top: 6px;
  font-weight: bold;
  @media (max-width: 350px) {
    font-size: 8px;
  }
`;

export const TabSwitcherUnderline = styled(motion.span)`
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
`;
