import React from "react";
import {
  HeaderContainer,
  HeaderTitle,
  HeaderCount,
  HeaderTitleBadge,
  HeaderClose,
} from "./style";

const TinderHeader = ({ onClose, type, count }) => {
  return (
    <HeaderContainer>
      <HeaderTitle>
        <HeaderTitleBadge type={type} />
        {type === "pending"
          ? "მოლოდინში"
          : type === "approved"
          ? "დადასტურებული"
          : type === "blocked"
          ? "უარყოფილი"
          : "გადადებული"}
      </HeaderTitle>
      <HeaderCount>{count}</HeaderCount>
      <HeaderClose onClick={() => onClose()} src="/icons/close.svg" />
    </HeaderContainer>
  );
};

export default TinderHeader;
