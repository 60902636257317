import styled from "styled-components";

export const EmptyEntryWrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const EmptyEntryText = styled("span")`
  color: #e3e3e6;
  font-family: "BPG WEB 001 Caps";
  font-size: 18px;
  line-height: 21px;
  margin-top: 15px;
`;

export const EmptyEntryIcon = styled("img")`
  height: 48px;
  width: 48px;
`;
