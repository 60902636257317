import styled from "styled-components";

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
`;

export const HeaderLogo = styled("img")`
  height: 20px;
  width: 77px;
`;

export const HeaderIcon = styled("img")`
  height: 20px;
  width: 24px;
  object-fit: contain;
`;
