import React from "react";
import { EmptyEntryWrapper, EmptyEntryIcon, EmptyEntryText } from "./style";

const EmptyEntry = () => {
  return (
    <EmptyEntryWrapper>
      <EmptyEntryIcon src="/icons/empty.svg" />
      <EmptyEntryText>სია ცარიელია</EmptyEntryText>
    </EmptyEntryWrapper>
  );
};
export default EmptyEntry;
