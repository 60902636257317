import React, { useState } from "react";
import { InputContainer, InputLabel, InputElement } from "./style";

const Input = ({ title, onChange, type = "text", value, didError }) => {
  const [labelFloating, setLabelFloating] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  return (
    <InputContainer>
      <InputLabel active={labelFloating}>{title}</InputLabel>
      <InputElement
        didError={didError}
        type={type}
        value={inputValue}
        onFocus={() => setLabelFloating(true)}
        onBlur={() => setLabelFloating(!!inputValue)}
        onChange={(e) => {
          onChange(e.currentTarget.value);
          setInputValue(e.currentTarget.value);
        }}
      ></InputElement>
    </InputContainer>
  );
};

export default Input;
