import React from "react";
import {
  ListItemWrapper,
  ListItemName,
  ListItemStatusBadge,
  //   ListItemStatusName,
  ListItemArrow,
} from "./style";

const ListItem = (props) => {
  const { status, name } = props;
  return (
    <ListItemWrapper {...props}>
      <ListItemStatusBadge src={`/icons/${status}.svg`} />

      <ListItemName>{name}</ListItemName>
      {/* <ListItemStatusName status={status}>
        {status === "approved"
          ? "დადასტურებული"
          : status === "blocked"
          ? "უარყოფილი"
          : "დასადასტურებელი"}
      </ListItemStatusName> */}
      <ListItemArrow src="/icons/arrow.svg" />
    </ListItemWrapper>
  );
};

export default ListItem;
