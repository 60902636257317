import styled from "styled-components";
import { motion } from "framer-motion";

export const LoginDiv = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 25px;
  height: 100%;
`;

export const LogInImg = styled(motion.img)`
  height: 240px;
  width: 262px;
  margin-bottom: 45px;
  margin-top: 30px;
`;

export const LoginLogo = styled(motion.img)`
  height: 20px;
  width: 77px;
`;
