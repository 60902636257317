import styled from "styled-components";
import { motion } from "framer-motion";

export const CardListContainer = styled("div")`
  width: 100%;
  max-width: 320px;
  height: 350px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 500px) {
    max-width: 380px;
    height: 400px;
  }
`;

export const CardContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  touch-action: none;
`;

export const CardImageRatio = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CardImage = styled("img")`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: 13px;
  box-shadow: 0 4px 15px 1px rgba(10, 17, 52, 0.18);
  pointer-events: none;
`;

export const CardStatus = styled(motion.span)`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-family: "BPG WEB 002 Caps";
  border-radius: 6px;
  background-color: #ffffff;
  padding: 12px 22px;
  height: 37px;
`;

export const CardUserInfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 70px);
  padding: 15px;
  border: 1px solid rgba(159, 167, 192, 0.09);
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 11px 0 rgba(10, 31, 66, 0.07);
  position: absolute;
  left: 35px;
  bottom: -30px;
`;

export const CardUserDesc = styled("span")`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 15px;
  text-shadow: 0 0 0 #000;
`;

export const CardUserFBButton = styled("button")`
  border-radius: 6px;
  background: #1e73ed;
  color: #ffffff;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 15px;
  width: 100%;
  border: none;
  cursor: pointer;
`;

export const TutorialWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 38, 43, 0.37);
  z-index: 5;
`;

export const TutorialItem = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 6;
  min-width: 110px;
`;

export const TutorialItemIcon = styled(motion.img)`
  width: 66px;
  height: 71px;
  object-fit: contain;
`;

export const TutorialItemText = styled("div")`
  color: #ffffff;
  font-family: "BPG WEB 002 Caps";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin-top: 15px;
`;
