import React, { useState, useEffect } from "react";
import {
  CardContainer,
  CardImage,
  CardImageRatio,
  CardUserInfoContainer,
  CardUserDesc,
  CardUserFBButton,
  CardListContainer,
  CardStatus,
  TutorialWrapper,
  TutorialItemText,
  TutorialItemIcon,
  TutorialItem,
} from "./style";
import TinderActions from "../actions";
import { useSpring, useMotionValue } from "framer-motion";
import EmptyEntry from "../../common/emptyEntry";

let initialPosition = [0, 0];
let lastPosition = [0, 0];

const TinderCard = ({
  userList,
  swipeable = true,
  startUser,
  blockUser,
  approveUser,
  skipUser,
  onSetActiveUser,
  type,
  didTutorial,
  onTutorialEnd,
}) => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [released, setReleased] = useState(true);
  const [wait, setWait] = useState(false);
  const [currentPerson, setCurrentPerson] = useState(
    userList.findIndex((x) => x.UserId === startUser)
  );
  const [immediete, setImmediete] = useState(false);
  const [dragging, setDragging] = useState(false);
  const middleScale = useSpring(
    0.9,
    immediete
      ? { mass: 0.01, stiffness: 30000, damping: 150 }
      : {
          mass: 1,
          stiffness: released ? 600 : 3500,
          damping: released ? 60 : 80,
        }
  );
  const middleY = useSpring(
    -25,
    immediete
      ? { mass: 0.01, stiffness: 30000, damping: 150 }
      : {
          mass: 1,
          stiffness: released ? 600 : 3500,
          damping: released ? 60 : 80,
        }
  );
  const lastScale = useSpring(0.8, {
    mass: 1,
    stiffness: released ? 600 : 3500,
    damping: released ? 60 : 80,
  });
  const lastY = useSpring(-50, {
    mass: 1,
    stiffness: released ? 600 : 3500,
    damping: released ? 60 : 80,
  });
  const rotation = useSpring(
    0,
    immediete
      ? { mass: 0.01, stiffness: 30000, damping: 150 }
      : {
          mass: 1,
          stiffness: released ? 600 : 3500,
          damping: released ? 60 : 80,
        }
  );

  const dragX = useSpring(
    0,
    immediete
      ? { mass: 0.01, stiffness: 30000, damping: 150 }
      : {
          mass: 1,
          stiffness: released ? 600 : 3500,
          damping: released ? 60 : 80,
        }
  );

  const dragY = useSpring(
    0,
    immediete
      ? { mass: 0.01, stiffness: 30000, damping: 150 }
      : {
          mass: 1,
          stiffness: released ? 600 : 3500,
          damping: released ? 60 : 80,
        }
  );

  const approveOpacity = useMotionValue(0);

  const rejectOpacity = useMotionValue(0);

  const onDragStart = (e) => {
    setImmediete(false);
    initialPosition = [e.clientX, e.clientY];
    setReleased(false);
    setDragging(true);
  };

  const onDrag = (e) => {
    const currentPosition = [e.clientX, e.clientY];
    const deltaX = initialPosition[0] - currentPosition[0];
    const deltaY = initialPosition[1] - currentPosition[1];
    if (type === "approved") {
      if (deltaX > 20) {
        dragX.set(lastPosition[0] - deltaX);
      }
    } else if (type === "blocked") {
      if (deltaX < 20) {
        dragX.set(lastPosition[0] - deltaX);
      }
    } else {
      dragX.set(lastPosition[0] - deltaX);
    }
    dragY.set(lastPosition[1] - deltaY);
    rotation.set(deltaX / 20);
    if (deltaX < -20) {
      rejectOpacity.set(0);
      approveOpacity.set(Math.abs(deltaX / 100));
    } else if (deltaX > 20) {
      rejectOpacity.set(Math.abs(deltaX / 100));
      approveOpacity.set(0);
    } else {
      approveOpacity.set(0);
      rejectOpacity.set(0);
    }
  };

  const onDragEnd = (e) => {
    if (!dragging) {
      return;
    }
    if (wait) {
      return;
    }
    const currentPosition = [e.clientX, e.clientY];
    const deltaX = initialPosition[0] - currentPosition[0];
    setReleased(true);
    lastPosition = [0, 0];
    const velocity = dragX.getVelocity();
    if ((velocity > 250 || deltaX < -100) && type !== "approved") {
      dragX.set(380);
      dragY.set((dragY.get() * velocity) / 200);
      lastScale.set(0.9);
      middleScale.set(1);
      lastY.set(-25);
      middleY.set(0);
      setWait(true);
    } else if ((velocity < -250 || deltaX > 100) && type !== "blocked") {
      dragX.set(-380);
      dragY.set((dragY.get() * -velocity) / 200);
      lastScale.set(0.9);
      middleScale.set(1);
      lastY.set(-25);
      middleY.set(0);
      setWait(true);
    } else {
      dragX.set(0);
      dragY.set(0);
      rotation.set(0);
      approveOpacity.set(0);
      rejectOpacity.set(0);
    }
    setImmediete(true);
    setTimeout(() => {
      if ((velocity > 250 || deltaX < -100) && type !== "approved") {
        approveUser(pendingUsers[currentPerson].UserId);
      }
      if ((velocity < -250 || deltaX > 100) && type !== "blocked") {
        blockUser(pendingUsers[currentPerson].UserId);
      }
      if (Math.abs(velocity) > 250 || Math.abs(deltaX) > 100) {
        approveOpacity.set(0);
        rejectOpacity.set(0);
        dragX.set(0);
        dragY.set(0);
        rotation.set(0);
        if (
          ((velocity > 250 || deltaX < -100) && type !== "approved") ||
          ((velocity < -250 || deltaX > 100) && type !== "blocked")
        ) {
          onSetActiveUser(getNextUser());
        }
        lastScale.set(0.8);
        middleScale.set(0.9);
        lastY.set(-50);
        middleY.set(-25);
        dragX.set(0);
        setWait(false);
      }
    }, 250);
    setDragging(false);
  };

  const getNextUser = () => {
    if (pendingUsers.length > [currentPerson + 1]) {
      return pendingUsers[currentPerson + 1].UserId;
    } else if (pendingUsers.length > 0) {
      return pendingUsers[0].UserId;
    } else {
      return pendingUsers[currentPerson].UserId;
    }
  };

  const getFront = () => {
    if (pendingUsers.length < 1) {
      return null;
    }
    if (pendingUsers.length > [currentPerson]) {
      return pendingUsers[currentPerson];
    } else if (pendingUsers.length > 0) {
      return pendingUsers[0];
    } else {
      return null;
    }
  };
  const getMiddle = () => {
    if (pendingUsers.length < 2) {
      return null;
    }
    if (pendingUsers.length > [currentPerson + 1]) {
      return pendingUsers[currentPerson + 1];
    } else if (pendingUsers.length > 0) {
      return pendingUsers[0];
    } else {
      return null;
    }
  };
  const getBack = () => {
    if (pendingUsers.length < 3) {
      return null;
    }
    if (pendingUsers.length > [currentPerson + 2]) {
      return pendingUsers[currentPerson + 2];
    } else if (pendingUsers.length > 1) {
      return pendingUsers[1];
    } else {
      return null;
    }
  };
  const [frontUser, setFrontUser] = useState(null);
  const [middleUser, setMiddleUser] = useState(null);
  const [backUser, setBackUser] = useState(null);
  useEffect(() => {
    setPendingUsers(userList);
    setCurrentPerson(userList.findIndex((x) => x.UserId === startUser));
  }, [userList, startUser]);

  useEffect(() => {
    setFrontUser(getFront());
    setMiddleUser(getMiddle());
    setBackUser(getBack());
  }, [pendingUsers]);
  const noImageSrc = "/images/no-photo.png";
  const getAge = (dateStr) => {
    const year = new Date(dateStr).getFullYear();
    const date = new Date(dateStr).getDate();
    const month = new Date(dateStr).getMonth() + 1;
    return Math.floor(
      (new Date() - new Date(year, month, date).getTime()) / 3.15576e10
    );
  };

  return (
    <>
      {!didTutorial && (
        <TutorialWrapper
          onTouchEnd={() => {
            onTutorialEnd();
          }}
          onMouseUp={() => {
            onTutorialEnd();
          }}
        >
          <TutorialItem
            style={{
              left: 50,
              top: "25%",
            }}
          >
            <TutorialItemIcon
              animate={{
                rotate: 20,
              }}
              transition={{
                yoyo: Infinity,
                duration: 0.6,
                ease: "easeInOut",
              }}
              src="/icons/swipe-right.svg"
            />
            <TutorialItemText>დადასტურება</TutorialItemText>
          </TutorialItem>
          <TutorialItem
            style={{
              right: 50,
              top: "25%",
            }}
          >
            <TutorialItemIcon
              animate={{
                rotate: -20,
              }}
              transition={{
                yoyo: Infinity,
                duration: 0.6,
                ease: "easeInOut",
              }}
              src="/icons/swipe-left.svg"
            />
            <TutorialItemText>უარყოფა</TutorialItemText>
          </TutorialItem>
          <TutorialItem
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              top: "68%",
            }}
          >
            <TutorialItemIcon
              animate={{
                y: 10,
              }}
              transition={{
                yoyo: Infinity,
                duration: 0.6,
                ease: "easeInOut",
              }}
              src="/icons/tap.svg"
            />
            <TutorialItemText>უარყოფა, გადადება, დადასტურება</TutorialItemText>
          </TutorialItem>
        </TutorialWrapper>
      )}
      {<EmptyEntry />}
      <CardListContainer>
        {swipeable && (
          <>
            {backUser && (
              <CardContainer
                style={{
                  scale: lastScale,
                  y: lastY,
                }}
              >
                <CardImageRatio>
                  <CardImage
                    src={
                      backUser.FBProfilePhoto
                        ? backUser.FBProfilePhoto
                        : noImageSrc
                    }
                  />
                </CardImageRatio>
                <CardUserInfoContainer>
                  <CardUserDesc>
                    {backUser.FullName}
                    {backUser.BirthDate
                      ? ", " + getAge(backUser.BirthDate)
                      : ""}
                  </CardUserDesc>
                  <CardUserFBButton>Facebook-ზე გადასვლა</CardUserFBButton>
                </CardUserInfoContainer>
              </CardContainer>
            )}
            {middleUser && (
              <CardContainer
                style={{
                  scale: middleScale,
                  y: middleY,
                }}
              >
                <CardImageRatio>
                  <CardImage
                    src={
                      middleUser.FBProfilePhoto
                        ? middleUser.FBProfilePhoto
                        : noImageSrc
                    }
                  />
                </CardImageRatio>
                <CardUserInfoContainer>
                  <CardUserDesc>
                    {middleUser.FullName}
                    {middleUser.BirthDate
                      ? ", " + getAge(middleUser.BirthDate)
                      : ""}
                  </CardUserDesc>
                  <CardUserFBButton>Facebook-ზე გადასვლა</CardUserFBButton>
                </CardUserInfoContainer>
              </CardContainer>
            )}
            {frontUser && (
              <CardContainer
                onPan={onDrag}
                onPanStart={onDragStart}
                onPanEnd={onDragEnd}
                style={{
                  rotate: rotation,
                  x: dragX,
                  y: dragY,
                  opacity: 1,
                }}
              >
                <CardImageRatio>
                  <CardImage
                    src={
                      frontUser.FBProfilePhoto
                        ? frontUser.FBProfilePhoto
                        : noImageSrc
                    }
                  />
                </CardImageRatio>
                <CardStatus
                  style={{ color: "#5FE7B9", opacity: approveOpacity }}
                >
                  დადასტურება
                </CardStatus>
                <CardStatus
                  style={{ color: "#FE1153", opacity: rejectOpacity }}
                >
                  დაბლოკვა
                </CardStatus>
                <CardUserInfoContainer>
                  <CardUserDesc>
                    {frontUser.FullName}
                    {frontUser.BirthDate
                      ? ", " + getAge(frontUser.BirthDate)
                      : ""}
                  </CardUserDesc>
                  <a
                    href={frontUser.FBProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "100%" }}
                  >
                    <CardUserFBButton>Facebook-ზე გადასვლა</CardUserFBButton>
                  </a>
                </CardUserInfoContainer>
              </CardContainer>
            )}
          </>
        )}
        {!swipeable && (
          <CardContainer>
            <CardImageRatio>
              <CardImage
                src={
                  pendingUsers[currentPerson].FBProfilePhoto
                    ? pendingUsers[currentPerson].FBProfilePhoto
                    : noImageSrc
                }
              />
            </CardImageRatio>
            <CardStatus style={{ color: "#5FE7B9", opacity: approveOpacity }}>
              დადასტურება
            </CardStatus>
            <CardStatus style={{ color: "#FE1153", opacity: rejectOpacity }}>
              დაბლოკვა
            </CardStatus>
            <CardUserInfoContainer>
              <CardUserDesc>
                {pendingUsers[currentPerson].FullName}
                {pendingUsers[currentPerson].BirthDate
                  ? ", " + getAge(pendingUsers[currentPerson].BirthDate)
                  : ""}
              </CardUserDesc>
              <a
                href={pendingUsers[currentPerson].FBProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardUserFBButton>Facebook-ზე გადასვლა</CardUserFBButton>
              </a>
            </CardUserInfoContainer>
          </CardContainer>
        )}
      </CardListContainer>
      <TinderActions
        onBlock={() => {
          if (wait || pendingUsers.length < 1) {
            return;
          }
          setWait(true);
          setReleased(true);
          dragX.set(-380);
          lastScale.set(0.9);
          middleScale.set(1);
          lastY.set(-25);
          middleY.set(0);
          setImmediete(true);
          setTimeout(() => {
            blockUser(pendingUsers[currentPerson].UserId);
            approveOpacity.set(0);
            rejectOpacity.set(0);
            dragX.set(0);
            dragY.set(0);
            rotation.set(0);
            onSetActiveUser(getNextUser());
            lastScale.set(0.8);
            middleScale.set(0.9);
            lastY.set(-50);
            middleY.set(-25);
            dragX.set(0);
            setImmediete(false);
            setWait(false);
          }, 250);
        }}
        onApprove={() => {
          if (wait || pendingUsers.length < 1) {
            return;
          }
          setWait(true);
          setReleased(true);
          dragX.set(380);
          lastScale.set(0.9);
          middleScale.set(1);
          lastY.set(-25);
          middleY.set(0);
          setImmediete(true);
          setTimeout(() => {
            approveUser(pendingUsers[currentPerson].UserId);
            approveOpacity.set(0);
            rejectOpacity.set(0);
            dragX.set(0);
            dragY.set(0);
            rotation.set(0);
            onSetActiveUser(getNextUser());
            lastScale.set(0.8);
            middleScale.set(0.9);
            lastY.set(-50);
            middleY.set(-25);
            dragX.set(0);
            setImmediete(false);
            setWait(false);
          }, 250);
        }}
        onSkip={() => {
          if (wait || pendingUsers.length < 1) {
            return;
          }
          setWait(true);
          setReleased(true);
          dragX.set(0);
          dragY.set(600);
          lastScale.set(0.9);
          middleScale.set(1);
          lastY.set(-25);
          middleY.set(0);
          setImmediete(true);
          setTimeout(() => {
            skipUser(pendingUsers[currentPerson].UserId);
            approveOpacity.set(0);
            rejectOpacity.set(0);
            dragX.set(0);
            dragY.set(0);
            rotation.set(0);
            onSetActiveUser(getNextUser());
            lastScale.set(0.8);
            middleScale.set(0.9);
            lastY.set(-50);
            middleY.set(-25);
            dragX.set(0);
            setImmediete(false);
            setWait(false);
          }, 250);
        }}
        type={type}
      />
    </>
  );
};

export default TinderCard;
