import styled from "styled-components";
import ListItem from "../common/listItem";

export const SearchContainer = styled("div")`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  position: fixed;
  overflow-y: auto;
  z-index: 3;
  background-color: #fff;
  padding-top: 50px;
`;

export const SearchInputWrapper = styled("div")`
  border-radius: 7px;
  background-color: #f1f4f7;
  height: 37px;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  border-radius: 7px;
  background-color: #f1f4f7;
  height: 37px;
  position: fixed;
  width: calc(100% - 30px);
  margin-bottom: 25px;
  display: flex;
  top: 15px;
  left: 15px;
  box-shadow: 0 -50px 0 0 #fff;
`;

export const SearchInput = styled("input")`
  border: none;
  outline: none;
  background-color: #f1f4f7;
  padding: 9px 0px;
  font-family: FiraGO;
  font-size: 16px;
  line-height: 19px;
  color: #25262b;
  position: absolute;
  left: 40px;
  appearance: none;
  padding: 0;
  height: 37px;
  width: calc(100% - 70px);
`;

export const SearchIcon = styled("img")`
  position: absolute;
  height: 19px;
  width: 19px;
  top: 9px;
  left: 12px;
  z-index: 1;
`;

export const CloseIcon = styled("img")`
  position: absolute;
  height: 16px;
  width: 16px;
  top: 11px;
  right: 12px;
  z-index: 1;
`;

export const SearchListItem = styled(ListItem)`
  padding: 10px 5px;
  background-color: #fff !important;
  border-bottom: 1px solid rgba(87, 95, 139, 0.09);
`;
